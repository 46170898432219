
$blue: #337ab7

// Include any default variable overrides here (though functions won't be available)

@import "bootstrap/scss/bootstrap"

// Then add additional custom code here

pre
  font-family: var(--bs-body-font-family)

.navbar-toggler:focus
  --#{$prefix}navbar-toggler-focus-width: 0.25rem
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 var(--#{$prefix}navbar-toggler-focus-width) #{$gray-500}

.badge
  --#{$prefix}badge-font-size: 0.7em
  
.btn-outline-secondary
  --#{$prefix}btn-color: #{$gray-700}
  --#{$prefix}btn-hover-color: #{$gray-700}
  --#{$prefix}btn-hover-bg: #{$gray-200}
  --#{$prefix}btn-border-color: #{$gray-400}
  --#{$prefix}btn-hover-border-color: #{$gray-400}
  
.form-control:focus,
.form-select:focus,
.form-check-input:focus,
.btn:focus-visible
    border-color: #{$blue-300} //original color: #66afe9
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
    outline: 0

a
  text-decoration: none
  
a:hover
  text-decoration: underline
  
.btn:hover
  text-decoration: none