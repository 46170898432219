@use "_variables" as var

.middle
  width: 100%
  position: absolute
  top: var.$header-height
  bottom: var.$footer-height
  
  @media (max-width: var.$mobile-view-width)
    bottom: 0

.middle:after
  display: table
  clear: both
  content: ''

.content
  padding: 10px 10px 0 10px
  position: absolute
  top: 0
  bottom: 0
  overflow: auto
  width: 100%
  
  @media(min-width: var.$mobile-view-width + 1)
    padding-left: var.$left-sidebar-width + 10