@use "_variables" as var

.footer
  min-width: 480px
  height: var.$footer-height
  position: absolute
  bottom: 0
  width: 100%
  border-top: 1px solid #ddd
  padding: 7px 10px

  @media (max-width: var.$mobile-view-width)
    display: none