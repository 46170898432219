.logoicon
  margin-top: -10px

.header-link
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  text-decoration: none
  
.header-link:hover,
.header-link:active,
.header-link:focus
  text-decoration: none

.header-link:hover,
.header-link:focus
  color: #fff