@use "_variables" as var

.left-sidebar
  width: var.$left-sidebar-width
  position: relative
  height: 100%
  overflow: auto
  padding: 5px 10px

  @media (max-width: var.$mobile-view-width)
    display: none
    
.left-panel-tool
    color: #9d9d9d
    cursor: pointer