html {
    height: 100%;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}
body {
    /*font: 16px/18px Arial, sans-serif;*/
    height: 100%;
    overflow: hidden;
}
#root {
    width: 100%;
    min-width: 300px;
    min-height: 100%;
    height: auto !important;
}

.textarea-rows-5 {
    height: 10em !important;
}

/* Header
-----------------------------------------------------------------------------*/
.header {
    height: 50px;
}


/* Toolbar
-----------------------------------------------------------------------------*/
.content .btn-toolbar {
    margin: 10px 0 20px 0;
}


/* Thing edit
-----------------------------------------------------------------------------*/

#date-picker-start > .MuiDateCalendar-root {
    margin-left: 0 !important;
}

/* Thing details
-----------------------------------------------------------------------------*/

.thing-title {
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}


/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Carousel */
.carousel-caption p {
    font-size: 20px;
    line-height: 1.4;
}

/* buttons and links extension to use brackets: [ click me ] */
.btn-bracketed::before {
    display:inline-block;
    content: "[";
    padding-right: 0.5em;
}
.btn-bracketed::after {
    display:inline-block;
    content: "]";
    padding-left: 0.5em;
}

.nav > li.divider {
    border-top: 1px solid #333;
    margin: 5px 0;
}

.toolbar-btn {
    margin-right: 16px;
}

.toolbar-btn > form {
    display: inline;
}

ul.left-side-panel > li > ul > li > a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



.thing-row {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
}

.thing-row:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.thing-row .thing-link {
    overflow: hidden;
    width: 100%;
    color: #555;
}

.thing-link,
.thing-link:hover,
.thing-link:active,
.thing-link:focus {
    text-decoration: none;
}

.thing-row .thing-caption {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.thing-row .thing-tools {
    margin: 0 20px 0 0;
}

.more-row {
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
    text-align: center;
}

.more-row:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.header-mobile-btn {
    color: #9d9d9d;
    font-size: x-large;
    margin: 14px;
}

.header-mobile-btn.on {
    color: #fff;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}


/* TODO delete? */
#thing-edit-navbar {
    padding-top: 20px;
}


/* Project/Context toolbar
-----------------------------------------------------------------------------*/

.toolbar-caption {
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

