// Override bootstrap
// -----------------------------------------------------

h5
    font-size: 16px !important
    font-weight: bold !important

    .glyphicon
        top: -3px !important

/* иконка съезжает ниже */
.top-current-state
    .glyphicon
        top: -2px !important

